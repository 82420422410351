import { Canvas } from "@react-three/fiber";
import {
  Fisheye,
  CameraControls,
  PerspectiveCamera,
  Environment,
} from "@react-three/drei";
import { Level, Sudo, Camera, Cactus, Box, Painting, Coin } from "./Scene";
import SongPreview from "./SongPreviewOverlay";

export default function App() {
  return (
    <>
      <Canvas flat>
        <Fisheye zoom={0}>
          <CameraControls minPolarAngle={0} maxPolarAngle={Math.PI / 1.6} />
          <ambientLight intensity={Math.PI / 2} />
          <group scale={20} position={[5, -11, -5]}>
            <Level />
            <Sudo />
            <Camera />
            <Cactus />
            <Painting />
            <Coin />
          </group>
          <Environment preset="city" background blur={1} />
          <PerspectiveCamera makeDefault position={[0, 0, 18.5]} />
        </Fisheye>
      </Canvas>
      <SongPreview />
    </>
  );
}
