import React, { useState } from "react";
import ReactPlayer from "react-player";
import "./SongPreviewOverlay.css";

const SongPreview = () => {
  const [playing, setPlaying] = useState(false);

  const togglePlay = () => {
    setPlaying(true);
  };

  return (
    <div className="song-preview" onClick={togglePlay}>
      <div className="song-info">
        <h3 className="song-name">Rock and Roll</h3>
        <p className="date-venue">Jun 5, 2011 - The Gorge, George, WA</p>
      </div>
      <div className="waveform">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ReactPlayer
        url="/track.mp3"
        playing={playing}
        loop={true}
        controls={false}
        volume={1}
        width={0}
        height={0}
      />
    </div>
  );
};

export default SongPreview;
